import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, setDoc, getDoc } from 'firebase/firestore';
import { getAuth, } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL, storageRef } from 'firebase/storage';



const firebaseConfig = {
  apiKey: "AIzaSyArvfkTo-oQDxsKaPLv-x2gLgBjvRv3mr0",
  authDomain: "partners-191ef.firebaseapp.com",
  projectId: "partners-191ef",
  storageBucket: "partners-191ef.appspot.com",
  messagingSenderId: "890022191436",
  appId: "1:890022191436:web:71074e30264358d63fafe9",
  measurementId: "G-B37EKZFFE0"
  };

// Initialize Firebase and Firestore
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();

const db = getFirestore(app);
const storage = getStorage(app);

// Función para subir una imagen
export const uploadImage = async (uid, file) => {
  if (!uid) throw new Error("Usuario no autenticado.");

  const storage = getStorage();
  const filePath = `users/${uid}/images/${file.name}`;
  const storageRef = ref(storage, filePath);

  try {
    const snapshot = await uploadBytes(storageRef, file);

    // Si necesitas la URL pública de la imagen
    const url = await getDownloadURL(snapshot.ref);

    return url;
  } catch (error) {
    throw new Error("Error al subir la imagen: ", error);
  }
};

export const getPartnerData = async (partnerId) => {
  const docRef = doc(db, "partners", partnerId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.log("No se encontraron datos del partner.");
    return null; // O puedes retornar un objeto vacío dependiendo de tu lógica
  }
};

export const updatePartner = async (partnerId, partnerData) => {
  // Firebase v9+ utiliza la nueva API modular
  const docRef = doc(db, 'partners', partnerId);
  await setDoc(docRef, partnerData, { merge: true });
  console.log('Partner actualizado con éxito');
};

export const uploadCroppedImage = async (userId, image) => {
  const storage = getStorage();
  const imageRef = ref(storage, `partners/${userId}/logo.jpg`);
  await uploadBytes(imageRef, image);

  // Obtener la URL pública de la imagen subida
  const url = await getDownloadURL(imageRef);
  console.log('URL pública de la imagen:', url);
  return url; // Retornamos la URL para su uso posterior
};