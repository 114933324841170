import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  VStack,
  Link,
  AlertDescription,
  Container,
} from '@chakra-ui/react';
import { getPartnerData } from '../firebase.utils'; // Asegúrate de tener esta función
import { useNavigate } from 'react-router-dom';
import PartnerLevelsTimeline from './PartnerLevelsTimeline';

function Profile({ user }) {
  const [profileData, setProfileData] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchProfileData = async () => {
      if (user?.uid) {
        const data = await getPartnerData(user.uid);
        if (data) {
          setProfileData(data);
        } else {
          navigate('/register');
        }
      }
    };

    fetchProfileData();
  }, [user, navigate, toast]);

  // Content placeholders para simular una lista borrosa   
  const Placeholder = () => (
    <Box px={5} py={2} w="full" bg="gray.200" borderRadius="md">
      <Box h="10px" bg="gray.300" borderRadius="full" />
    </Box>
  );

  return (
    <Container maxW="container.xxl" centerContent m={10} mt={20}>
      <Flex direction={{ base: "column", md: "row" }} gap={10} mb={10} w="full" alignItems="flex-start">
      { profileData &&
      (<PartnerLevelsTimeline currentLevel={profileData.level} user={user} />)
        }
              </Flex>
        {/* Perfil */}
        <Flex direction={{ base: "column", md: "row" }} gap={10} w="full" alignItems="flex-start">
        <Box bg="white" p={5} shadow="lg" borderWidth="1px" borderRadius="lg" w={{ md: "40%" }} h="fit-content">
          <Heading size="lg" mb={4}>Perfil de la Empresa</Heading>
          {profileData && (
            <>
              <VStack spacing={4} align="flex-start">
                <Image
                  src={profileData.logoUrl || 'https://via.placeholder.com/150'}
                  alt={`Logo de ${profileData.companyName}`}
                  objectFit="contain"
                  boxSize="150px"
                  fallbackSrc="https://via.placeholder.com/150"
                  borderRadius="md"
                />
                <Text fontWeight="bold">{profileData.companyName}</Text>
                <Text>{profileData.companyDescription}</Text>
                <Text>Tamaño: {profileData.companySize}</Text>
                {profileData.technologies.length > 0 && <Text>Tecnologías: {profileData.technologies.join(", ")}</Text>}
                {profileData.projectTypes.length > 0 && <Text>Tipos de proyectos: {profileData.projectTypes.join(", ")}</Text>}
              </VStack>
              <Button colorScheme="blue" mt={5} onClick={() => navigate('/register')}>Editar perfil</Button>
            </>
          )}
        </Box>

        {/* Oportunidades */}
        <Box w={{ md: "50%" }}>
        <Box
    textAlign="center"
    width={{ base: 'full', md: '100%' }}
    px={{ base: 2, md: 5 }}
    pt={10} // Iguala el padding superior para que comience en la misma línea
    pb={5} // Padding inferior para asegurar espacio
  >
    <Heading as="h3" size="xl" mb={12}>
      Participa de licitaciones y proyectos de Business Intelligence
    </Heading>
    <Text fontSize="md" color="gray.600" mb={0}>
      Amplía tu alcance empresarial y explora una riqueza de oportunidades en Business Intelligence de proyectos de nuestros socios.
    </Text>
  </Box>
          <Alert status="info" borderRadius="lg" mb={5}>
            <AlertIcon />
            <Box>
  <AlertTitle>Autorización en Proceso</AlertTitle>
  <AlertDescription>
    Tu autorización como partner oficial está siendo procesada. Una vez aceptada, tendrás acceso a una diversidad de proyectos listos para beneficiarse de tu expertise. ¡Prepárate para dar el siguiente paso!
  </AlertDescription>
</Box>
          </Alert>
          <VStack>
            <Placeholder />
            <Placeholder />
            <Placeholder />
          </VStack>
        </Box>
      </Flex>
    </Container>
  );
}

export default Profile;