import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Checkbox,
  Text,
  Icon,
  Flex,
  Image,
  CheckboxGroup,
  Stack,
  Center,
  Skeleton,
  Heading,
  Container,
  useToast
} from '@chakra-ui/react';
import { updatePartner, uploadImage, getPartnerData } from '../firebase.utils';
import { FaUpload } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const RegisterPartnerForm = ({ user }) => {
  const [formData, setFormData] = useState({
    companyName: '',
    companySize: '',
    technologies: [],
    billingLastYear: '',
    projectTypes: [],
  });
  const [partnerId, setPartnerId] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [previewLogo, setPreviewLogo] = useState('');
  const toast = useToast();
  const [logoFile, setLogoFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPartnerData = async () => {
      if (user?.uid) {
        const fetchedData = await getPartnerData(user.uid);
        setFormData(fetchedData || {});
        setLogoUrl(fetchedData?.logoUrl || '');
        setPreviewLogo(fetchedData?.logoUrl || '');
      }
    };

    fetchPartnerData();
  }, [user]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setLogoFile(file);
    if (!file) {
      return;
    }
  
    // Valida el tipo de archivo
    if (!file.type.startsWith("image/")) {
      toast({
        title: 'Archivo no válido',
        description: 'Por favor, selecciona una imagen.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  
    // Valida el tamaño del archivo (2MB máximo)
    if (file.size > 2 * 1024 * 1024) {
      toast({
        title: 'Archivo demasiado grande',
        description: 'El tamaño de la imagen no debe exceder los 2MB.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  
    // Leer dimensiones de la imagen para validar
    const img = new window.Image();
    img.src = URL.createObjectURL(file);
    img.onload = async () => {
      // Valida las dimensiones de la imagen (900x900 máximo)
      if (img.width > 900 || img.height > 900) {
        toast({
          title: 'Imagen demasiado grande',
          description: 'Las dimensiones de la imagen no deben exceder 900x900 píxeles.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
  
        URL.revokeObjectURL(img.src);  // Limpia la URL creada
        return;
      }
  
      try {
        // Procede con la carga si pasa todas las validaciones
        const uploadedLogoUrl = await uploadImage(user.uid, file);
        console.log('URL de la imagen subida:', uploadedLogoUrl);
        setFormData({ ...formData, logoUrl: uploadedLogoUrl });
        await updatePartner(user.uid, { ...formData, logoUrl: uploadedLogoUrl });
        setLogoUrl(uploadedLogoUrl);
        setPreviewLogo(uploadedLogoUrl);
        toast({
          title: 'Imagen cargada',
          description: 'El logo ha sido actualizado con éxito.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
  
        URL.revokeObjectURL(img.src);  // Limpia la URL creada
      } catch (error) {
        toast({
          title: 'Error al cargar la imagen',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };
    img.onerror = () => {
      toast({
        title: 'Error al leer la imagen',
        description: 'No se pudo leer la imagen seleccionada.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      URL.revokeObjectURL(img.src);  // Limpia la URL creada en caso de error al cargar la imagen
    };
  };


  useEffect(() => {
    if (user?.uid) {
      setPartnerId(user.uid);
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setFormData((prevState) => {
        // Asegura que exista un array para esparcir, incluso si es la primera vez y está undefined
        const existingValues = prevState[name] || [];
        const newValues = checked ? [...existingValues, value] : existingValues.filter((item) => item !== value);
        return { ...prevState, [name]: newValues };
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!logoFile && !logoUrl) {
      toast({
        title: 'Imagen requerida',
        description: 'Por favor, carga el logo de la empresa.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setIsSubmitting(true);
    try {
      const completedFormData = { ...formData, finished: true, logoUrl };
      await updatePartner(partnerId, completedFormData);
      toast({
        title: 'Registro completado.',
        description: 'Partner registrado y formulario completado con éxito.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      navigate('/');
    } catch (error) {
      console.log(error);
      toast({
        title: 'Error en el registro.',
        description: 'No se pudo completar el registro. Por favor, inténtalo de nuevo.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    finally {
      setIsSubmitting(false);
    }
    
  };

  return (
    <Container centerContent mt={10} mb={10}>
      <Box w="600px" p={5} boxShadow="xl" borderRadius="lg" backgroundColor="white" mb={10}>
      {isSubmitting ? (
          <Center><Skeleton height="200px" /></Center>
        ) : (
          <>
        <Heading as="h3" size="lg" mb="6" textAlign="center">Registro de Partner</Heading>
        <form onSubmit={handleSubmit}>
          <FormControl id="companyName" isRequired>
            <FormLabel>Nombre de la empresa</FormLabel>
            <Input name="companyName" value={formData.companyName || ''} onChange={handleChange} />
          </FormControl>

          <FormControl id="companyDescription" isRequired mt="4">
            <FormLabel>Descripción de la empresa</FormLabel>
            <Textarea name="companyDescription" value={formData.companyDescription || ''} onChange={handleChange} />
          </FormControl>
          <FormControl mt={4}>
          <FormLabel>Logo de la empresa</FormLabel>
          <Flex flexDirection="column" alignItems="center" justifyContent="center" p={5} border="2px dashed gray" borderRadius="md">
            {previewLogo ? (
              <Image src={previewLogo} alt="Preview logo" boxSize="100px" objectFit="cover" />
            ) : (
              <Icon as={FaUpload} w={12} h={12} color="gray.500" />
            )}
            <Text mt={2}>Arrastra aquí o haz clic para seleccionar un archivo</Text>
            <Input id="file-input" name="companyLogo" type="file" accept="image/*" onChange={handleFileChange} style={{ display: 'none' }}/>
            <Button as="label" htmlFor="file-input" mt={2} colorScheme="blue" cursor="pointer" size="sm">Seleccionar archivo</Button>
          </Flex>
        </FormControl>
          <FormControl id="companySize" isRequired mt="4">
            <FormLabel>Tamaño de la empresa (Nro. de colaboradores)</FormLabel>
            <Select name="companySize" onChange={handleChange} value={formData.companySize}>
              <option value="">Seleccione...</option>
              <option value="1-3">1-3</option>
              <option value="4-10">4-10</option>
              <option value="11-50">11-50</option>
              <option value="51-200">51-200</option>
              <option value="201-500">201-500</option>
              <option value="501+">501+</option>
            </Select>
          </FormControl>

          <FormControl id="billingLastYear" isRequired>
                <FormLabel>Ingresos en los últimos 12 meses</FormLabel>
                <Select name="billingLastYear" onChange={handleChange} value={formData.billingLastYear || ''} placeholder="Seleccione un rango">
                  <option value="0-20K">0-20K USD</option>
                  <option value="20K-150K">20K-150K USD</option>
                  <option value="150K-500K">150K-500K USD</option>
                  <option value="500K-1.5M">500K-1.5M USD</option>
                  <option value="1.5M-3M">1.5M-3M USD</option>
                  <option value="3M-5M">3M-5M USD</option>
                  <option value="5M-10M">5M-10M USD</option>
                  <option value="10M-50M">10M-50M USD</option>
                  <option value="50M-100M">50M-100M USD</option>
                  <option value="+100M">Más de 100M USD</option>
                </Select>
              </FormControl>

          <FormControl id="technologies" mt="4">
            <FormLabel>Tecnologías que utilizan</FormLabel>
            <CheckboxGroup name="technologies"  value={formData.technologies}>
            <Stack spacing={3} direction="column">
      <Checkbox value="Power BI" name="technologies" onChange={handleChange}>Power BI</Checkbox>
      <Checkbox value="Looker Studio" name="technologies" onChange={handleChange}>Looker Studio</Checkbox>
      <Checkbox value="Tableau" name="technologies" onChange={handleChange}>Tableau</Checkbox>
      <Checkbox value="Qlik" name="technologies" onChange={handleChange}>Qlik</Checkbox>
      <Checkbox value="SAS Business Intelligence" name="technologies" onChange={handleChange}>SAS Business Intelligence</Checkbox>
      <Checkbox value="SAP BusinessObjects" name="technologies" onChange={handleChange}>SAP BusinessObjects</Checkbox>
      <Checkbox value="Oracle BI" name="technologies" onChange={handleChange}>Oracle BI</Checkbox>
      <Checkbox value="IBM Cognos Analytics" name="technologies" onChange={handleChange}>IBM Cognos Analytics</Checkbox>
      <Checkbox value="Google Data Studio" name="technologies" onChange={handleChange}>Google Data Studio</Checkbox>
      <Checkbox value="Snowflake" name="technologies" onChange={handleChange}>Snowflake</Checkbox>
      <Checkbox value="Amazon Redshift" name="technologies" onChange={handleChange}>Amazon Redshift</Checkbox>
      <Checkbox value="Microsoft Azure Synapse Analytics" name="technologies" onChange={handleChange}>Microsoft Azure Synapse Analytics</Checkbox>
      <Checkbox value="Databricks" name="technologies" onChange={handleChange}>Databricks</Checkbox>
      <Checkbox value="Apache Spark" name="technologies" onChange={handleChange}>Apache Spark</Checkbox>
      <Checkbox value="Airflow" name="technologies" onChange={handleChange}>Airflow</Checkbox>
    </Stack>
            </CheckboxGroup>
          </FormControl>

          <FormControl id="projectTypes" mt="4">
            <FormLabel>Tipo de proyectos a abordar</FormLabel>
            <CheckboxGroup name="projectTypes"  value={formData.projectTypes}>
            <Stack spacing={3} direction="column">
      <Checkbox value="Integraciones" name="projectTypes" onChange={handleChange}>Integraciones de sistemas</Checkbox>
      <Checkbox value="Generar Dashboards" name="projectTypes" onChange={handleChange}>Generación de Dashboards</Checkbox>
      <Checkbox value="Analisis de Datos" name="projectTypes" onChange={handleChange}>Análisis de Datos</Checkbox>
      <Checkbox value="Servicios Data Science" name="projectTypes" onChange={handleChange}>Servicios de Data Science</Checkbox>
      <Checkbox value="Data Warehousing" name="projectTypes" onChange={handleChange}>Data Warehousing</Checkbox>
      <Checkbox value="Machine Learning" name="projectTypes" onChange={handleChange}>Proyectos de Machine Learning</Checkbox>
      <Checkbox value="Inteligencia Artificial" name="projectTypes" onChange={handleChange}>Inteligencia Artificial</Checkbox>
      <Checkbox value="Procesamiento de Lenguaje Natural" name="projectTypes" onChange={handleChange}>Procesamiento de Lenguaje Natural (NLP)</Checkbox>
      <Checkbox value="Analítica Predictiva" name="projectTypes" onChange={handleChange}>Analítica Predictiva</Checkbox>
      <Checkbox value="Optimización de Negocios" name="projectTypes" onChange={handleChange}>Optimización de Procesos de Negocio</Checkbox>
      <Checkbox value="Visualización de Datos" name="projectTypes" onChange={handleChange}>Visualización de Datos Avanzada</Checkbox>
      <Checkbox value="Gobernanza de Datos" name="projectTypes" onChange={handleChange}>Gobernanza de Datos</Checkbox>
      <Checkbox value="Seguridad de Datos" name="projectTypes" onChange={handleChange}>Seguridad de Datos</Checkbox>
      <Checkbox value="Blockchain" name="projectTypes" onChange={handleChange}>Aplicaciones Blockchain</Checkbox>
      <Checkbox value="IoT" name="projectTypes" onChange={handleChange}>Soluciones IoT (Internet de las Cosas)</Checkbox>
    </Stack>
            </CheckboxGroup>
          </FormControl>

          <Button mt={4} colorScheme="blue" type="submit" width="full" >Enviar</Button>
        </form>
        </>
        )}
      </Box>
    </Container>
  );
};

export default RegisterPartnerForm;