import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Flex,
  Heading,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Image,
  Spacer,
  extendTheme,
  IconButton,
  Link,
  Text,
  useToast,
  Avatar,
  useDisclosure
} from '@chakra-ui/react';
import {
  BrowserRouter as Router,
  Routes,
  Link as RouterLink,
  Route,
} from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { auth } from './firebase.utils';
import { getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider, signInWithEmailLink, isSignInWithEmailLink, sendSignInLinkToEmail, signOut,  } from 'firebase/auth';
import SignUpSignInModal from './components/SignUpSignInModal';
import Header from './components/Header';
import Profile from './components/Profile';
import RegisterPartnerForm from './components/RegisterPartnerForm';


function App() {
  const [user, setUser] = useState(null);
  const [currentCountry, setCurrentCountry] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [email, setEmail] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [apiKeyToken, setApiKeyToken] = useState(null);
  const toast = useToast();
  const [country, setCountry] = useState(localStorage.getItem('country') || 'chile');
  
  const setCountryAction = (country) => {
    setCountry(country);
    localStorage.setItem('country', country);
    if(country == "mexico"){
    }
    else{
    }
  };

  useEffect(() => {
    // Attach the auth listener to handle user authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user); 
      setIsAuthenticated(!!user);
      if (user) {
        // If authenticated, the user might have an apiKeyToken
        const token = user.getIdToken(); // Get the Firebase ID token
        setApiKeyToken(token);
      }
    });

    const handleEmailLinkSignIn = async (email) => {
      try {
        await signInWithEmailLink(auth, email, window.location.href);
        // Handle successful sign-in
        window.localStorage.removeItem('emailForSignIn');
        setIsAuthenticated(true);
        toast({
          title: 'Inicio de sesión exitoso.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // TODO: Redirect the user to main page or dashboard
      } catch (error) {
        console.error(error);
        toast({
          title: 'Error al iniciar sesión.',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    // Check for email link sign-in
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (email) {
        handleEmailLinkSignIn(email);
      } else {
        // Fallback if email is not in local storage (e.g., user changed device or cleared storage)
        email = window.prompt('Por favor, ingresa tu correo electrónico para confirmar');
        if (email) handleEmailLinkSignIn(email);
      }
    }

    // Unsubscribe from the auth listener when component unmounts
    return () => unsubscribe();
  }, []);




  const handleSignInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, new GoogleAuthProvider());
      if (result.user) {
        setUser(result.user);
        setIsAuthenticated(true);
        onClose(); // Close the SignUpSignInModal

        toast({
          title: 'Ingreso exitoso.',
          description: `Bienvenido ${result.user.displayName}!`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Authentication failed:", error);

      toast({
        title: 'Error al ingresar.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSignUpWithEmail = async () => {
    const actionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true,
    };
  
    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      onClose();
      toast({
        title: 'Revisa tu correo.',
        description: 'Te hemos enviado un enlace de inicio de sesión a tu email. Por favor, revisa tu bandeja de entrada.',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error.message);
      toast({
        title: 'Error al enviar el enlace de inicio de sesión.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };


  const handleLogout = async () => {
    await signOut(auth);
    setUser(null); // Reset user state to null after logout
    setIsAuthenticated(false);
  };


  return (
    <Router>
      <ChakraProvider >
      <Flex
          as="header"
          position="fixed"
          top={0}
          left={0}
          right={0}
          align="center"
          justify="space-between"
          p={4}
          bg="white"
          color="brand.headerTextSecondary"
          borderBottom="1px"
          borderColor="gray.200"
          boxShadow="sm"
          zIndex="banner"
        >
          <Header setCountry={setCountryAction} country={country} />
          <Spacer />
          <Spacer />
        <Flex
        as="header"
        align="center"
        justify="end"
        p={4}
      >
        <Spacer />
      </Flex>
          <Flex alignItems="center">
           
            {user ? (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<Avatar size="sm" src={user.photoURL} />}
              variant="outline"
              size="sm"
              mr={2}
            />
            <MenuList>
              <MenuItem  bg="brand.headerBg" onClick={handleLogout}>Cerrar sesión</MenuItem>
            </MenuList>
          </Menu>
        ) : ''}
          </Flex>
        </Flex>

      { user ? (<Box mt="4rem">
          <Routes>
            <Route
                path="/"
                element={<Profile user={user} />}
              />
               <Route
                path="/register"
                element={<RegisterPartnerForm user={user} />}
              />
          </Routes>
        </Box>) : ''}
        { !user  &&
         (<Routes>
         <Route
             key="SignUpSignInModal"
             path="/"
             element={<SignUpSignInModal
              isOpen={isOpen}
              onClose={onClose}
              setEmail={setEmail}
              signInWithGoogle={handleSignInWithGoogle}
              handleSignUpWithEmail={handleSignUpWithEmail}
              isAuthenticated={isAuthenticated}
              country={country}
            />}
           />
       </Routes>)
        }

<Box as="footer" width="full" py={4}>
  <Flex
    justify="space-between"
    align="center"
    direction={{ base: 'column', md: 'row' }}
    px={4}
    mt={10} // Añade un margen superior para asegurar un espacio entre el contenido y el footer
  >
    {/* Contenido izquierdo */}
    <Flex direction="column" align={{ base: 'center', md: 'start' }} mb={{ base: 4, md: 0 }}>
      <Link href="https://datascope.io/es/" isExternal fontSize="sm">
        Creado por DataScope.io
      </Link>
      <Link href="https://datascope.io/es/terms" isExternal fontSize="sm">
        Términos y condiciones
      </Link>
    </Flex>

    {/* Contenido derecho */}
    <Flex direction="column" align={{ base: 'center', md: 'end' }} display={{ base: 'none', md: 'flex' }}>
      <Text fontSize="sm">Contacto de Soporte</Text>
      <Text fontSize="sm">labs@datascope.io</Text>
    </Flex>
  </Flex>
</Box>
      </ChakraProvider>
    </Router>
  );
}

export default App;