import React from 'react';
import {
  Box,
  Heading,
  Text,
  Flex,
  Icon,
  Button,
  VStack,
  Spacer,
  IconButton,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  InputGroup,
  Input,
  ModalHeader,
  useClipboard,
  ModalBody,
  InputRightElement,
  ModalCloseButton,
  Link,
  ModalFooter
} from '@chakra-ui/react';
import { 
  FaChartLine, 
  FaExternalLinkAlt, 
  FaUser, 
  FaHandsHelping, 
  FaLightbulb, 
  FaAward,
  FaStar, 
  FaCopy,
  FaHandshake,
  FaQuestionCircle
} from 'react-icons/fa';

const PartnerLevelsTimeline = ({ currentLevelIndex = 0, user }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const colorScheme = useColorModeValue("blue.600", "blue.200");
  const refLink = `https://datascope.io?utm_source=partner_program&utm_campaign=data_partners&uid=${user.uid}`;
  const { hasCopied, onCopy } = useClipboard(refLink);
  const levels = [
    { title: 'Copper Starter', icon: FaUser, description: 'Empieza a explorar y conectar.'},
    { title: 'Bronze Collaborator', icon: FaHandsHelping, description: 'Fortalece la colaboración.', color: 'orange.500' },
    { title: 'Silver Innovator', icon: FaLightbulb, description: 'Destácate por la innovación.', color: 'gray.400' },
    { title: 'Gold Leader', icon: FaAward, description: 'Lidera y fomenta el crecimiento.', color: 'yellow.500' },
    { title: 'Platinum Visionary', icon: FaStar, description: 'Influye en el futuro de la tecnología.', color: 'purple.500' },
  ];
  const bgHover = useColorModeValue("blue.100", "blue.700"); 

  return (
    <Flex w="100%" mt={10} alignItems="center">
      <Flex flex="1" overflow="auto">
        {levels.map((level, index) => (
          <Box
            key={index}
            p={3}
            boxShadow="md"
            borderRadius="lg"
            bg={index === currentLevelIndex ? 'blue.50' : 'gray.50'}
            borderColor="gray.200"
            borderWidth="1px"
            opacity={index <= currentLevelIndex ? '1' : '0.4'}
            minWidth="180px"
            mx={2}
          >
            <VStack>
              <Icon as={level.icon} w={6} h={6} color={level.color ?? colorScheme} />
              <Heading size="xs" mt={2}>{level.title}</Heading>
              <Text fontSize="xs" mt={1} textAlign="center">{level.description}</Text>
            </VStack>
          </Box>
        ))}
    <Tooltip label="Cómo avanzar al siguiente nivel" hasArrow>
      <Box
        p={3}
        boxShadow="md"
        borderRadius="lg"
        bg={'blue.50'}
        borderColor="gray.200"
        borderWidth="1px"
        opacity={'1'}
        minWidth="180px"
        mx={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        transition="background-color 0.2s"
        _hover={{ bg: bgHover, cursor: "pointer"}}
        onClick={onOpen}
      >
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Text color="blue.600" fontWeight="bold" textAlign="center">
            Avanzar de Nivel
          </Text>
          <IconButton
            icon={<FaQuestionCircle />}
            aria-label="Cómo avanzar"
            colorScheme="blue"
            variant="ghost"
            _hover={{ background: "transparent" }}
            onClick={(e) => { e.stopPropagation(); onOpen(); }}
            size="md"
          />
        </Flex>
      </Box>
    </Tooltip>
      </Flex>


      {/* Modal para información sobre cómo avanzar de nivel */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cómo Avanzar al Siguiente Nivel</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={5} align="start">
            <Heading size="md" mb={2}>Incrementa tu participación:</Heading>

            <Box>
              <Flex alignItems="center">
                <Icon as={FaHandsHelping} mr={2} color={colorScheme} />
                <Text fontWeight="bold">Desarrollo de Proyectos BI para DataScope</Text>
              </Flex>
              <Text mt={2} pl={6} fontSize="sm">Colabora en proyectos BI, dando vida a los datos de los clientes de DataScope.</Text>
            </Box>

            <Box>
              <Flex alignItems="center">
                <Icon as={FaChartLine} mr={2} color={colorScheme} />
                <Text fontWeight="bold">Colaboraciones con Proyectos Propios</Text>
              </Flex>
              <Text mt={2} pl={6} fontSize="sm">Trae a tus clientes a DataScope y trabaja junto a nosotros en proyectos innovadores.</Text>
            </Box>

            <Text mt={4} fontSize="md">Prueba y comparte DataScope para avanzar y tener acceso a más proyectos:</Text>
            
            {/* Agregar aquí el input copiable */}
            <InputGroup>
              <Input pr="4.5rem" value={refLink} readOnly />
              <InputRightElement width="4.5rem">
                <Tooltip label={hasCopied ? "¡Copiado!" : "Copiar al portapapeles"} closeOnClick={false} openDelay={500}>
                  <IconButton
                    aria-label="Copiar enlace"
                    icon={<FaCopy />}
                    onClick={onCopy}
                    isRound
                  />
                </Tooltip>
              </InputRightElement>
            </InputGroup>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>Entendido</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    </Flex>
  );
}

export default PartnerLevelsTimeline;