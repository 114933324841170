// LandingPage.js

import React from 'react';
import { Box, SimpleGrid, Icon, Text, Button, Stack, Heading, Container, Flex, VStack, Center, Image } from '@chakra-ui/react';
import { FaGlobe, FaRocket, FaUsers, FaBullhorn, FaRegLightbulb, FaTrophy } from 'react-icons/fa';

// Iconos de funcionalidades representadas en componentes individuales.
const Feature = ({ title, icon, text }) => {
  return (
    <Stack>
      <Flex
            w={16}
            h={16}
            align={'center'}
            justify={'center'}
            color={'white'}
            rounded={'full'}
            bg={'gray.100'}
            mb={1}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={'gray.600'}>{text}</Text>
    </Stack>
  );
};

export default function LandingPage() {
  return (
    <>
    <Container maxW="100%" padding="0" margin="0">
        <Flex
          direction={{ base: 'column', md: 'row' }}
          justify="space-around"
          align="center"
          wrap="wrap"
          w="100%"
        >
          <Box w={{ base: '100%', md: '100%' }}  textAlign="center" padding="30px" pt={20} pb={40} bg='gray.100'>
          <Heading textAlign="center" mb="10" size="xl">
        Empieza tu viaje como Data Partner
      </Heading>
      <Text fontSize="lg" textAlign="center">
        Sigue estos sencillos pasos para unirte a nuestro programa y acceder a red de proyectos BI 
      </Text>
      <Text fontSize="lg" textAlign="center" mb="10">
      de cientos de empresas a nivel global de forma gratuita y sin comisiones.
      </Text>
      
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        <VStack spacing={4}>
          <Icon as={FaRegLightbulb} w={10} h={10} color="blue.500" />
          <Heading size="md">Regístrate</Heading>
          <Text textAlign="center">
            Completa el formulario de registro con los detalles de tu empresa.
          </Text>
        </VStack>

        <VStack spacing={4}>
          <Icon as={FaRocket} w={10} h={10} color="green.500" />
          <Heading size="md">Recibe Proyectos</Heading>
          <Text textAlign="center">
            Obtén notificaciones automáticas de proyectos que coincidan con tu perfil.
          </Text>
        </VStack>

        <VStack spacing={4}>
          <Icon as={FaTrophy} w={10} h={10} color="yellow.500" />
          <Heading size="md">Eleva tu Nivel</Heading>
          <Text textAlign="center">
            Aumenta tu nivel como partner completando proyectos y participando en iniciativas del programa.
          </Text>
        </VStack>
      </SimpleGrid>
          </Box>

          <Box w={{ base: '100%', md: '100%' }}  textAlign="center" padding="30px" bg=''>
    <Heading textAlign="center" mb={6} mt={15} size="lg">
       Accede a red de clientes de DataScope.io
    </Heading>

    <Text fontSize="lg" textAlign="center">
    Cientos de empresas usan DataScope.io para recolectar datos y necesitan apoyo en proyectos de Business Intelligence,
    </Text>
    <Text fontSize="lg" textAlign="center" mb={10}>
    unete a nuestra red de partners y accede a oportunidades de negocio.
    </Text>

    <Center>
        <Image src="/customers.png" alt="DataScope.io Customers" maxW="100%" mb={6}/>
      </Center>

      {/* Para insertar y centrar el video */}
      <Center>
      <Box
      as="video"
      loading="lazy"
      muted
      autoPlay
      loop
      src="/partnership.mp4"
      type="video/mp4"
      w="20%"
      mx="auto"
    />
      </Center>

    </Box>



<Box w={{ base: '100%', md: '100%' }}  textAlign="center" padding="30px" bg='gray.100'>
    <Heading textAlign="center" mb={6} mt={15} size="lg">
      Descubre los Beneficios de DataPartners
    </Heading>
    <Flex
      direction={{ base: 'column', md: 'row' }}
      justify="space-around"
      align="center"
      wrap="wrap"
      mt={20}
      px={{ base: 4, md: 24 }}
    >
    {/* Beneficio 1: Expansión de Oportunidades */}
    <Box textAlign="center" w={{ base: '100%', md: '25%' }} mb={10}>
      <Icon boxSize={10} as={FaGlobe} mb={4} color={'blue.300'} />
      <Heading size="md" mb={2}>
        Expansión de Oportunidades
      </Heading>
      <Text>
        Accede a un flujo constante de proyectos globales y amplía tu cartera de clientes.
      </Text>
    </Box>

    {/* Beneficio 2: Crecimiento Acelerado */}
    <Box textAlign="center" w={{ base: '100%', md: '25%' }} mb={10}>
      <Icon boxSize={10} as={FaRocket} mb={4} color={'blue.300'} />
      <Heading size="md" mb={2}>
        Crecimiento Acelerado
      </Heading>
      <Text>
        Conéctate con empresas líderes para proyectos que impulsen tu crecimiento.
      </Text>
    </Box>

    {/* Beneficio 3: Colaboración y Exposición */}
    <Box textAlign="center" w={{ base: '100%', md: '25%' }} mb={10}>
      <Icon boxSize={10} as={FaUsers} mb={4} color={'blue.300'} />
      <Heading size="md" mb={2}>
        Colaboración y Exposición
      </Heading>
      <Text>
        Aumenta tu visibilidad en el mercado trabajando junto a líderes de la industria.
      </Text>
    </Box>

    {/* Beneficio 4: Marketing y Publicidad */}
    <Box textAlign="center" w={{ base: '100%', md: '25%' }} mb={10}>
      <Icon boxSize={10} as={FaBullhorn} mb={4}  color={'blue.300'}/>
      <Heading size="md" mb={2}>
        Marketing y Publicidad
      </Heading>
      <Text>
        Benefíciate de nuestra plataforma y acciones de marketing sin costos adicionales.
      </Text>
    </Box>
    { /* add call to action */}
    <Box w={{ base: '100%', md: '100%' }} textAlign="center" padding="20px">
      <Center>
        <Heading size="md" mb={2}>
          ¿Listo para empezar?
        </Heading>
      </Center>
      <Center>
        <Text>
          Regístrate ahora y comienza a disfrutar de los beneficios de DataPartners.
        </Text>
      </Center>
      { /* add call to action button that brings to top */}
 
      <Button colorScheme="blue" size="lg" mt={4}  as="a" href="#">
        Regístrate
      </Button>

  </Box>

  </Flex>
    </Box>


    <Box w={{ base: '100%', md: '100%' }} textAlign="center" padding="20px">
  </Box>
  </Flex>
  </Container>
  </>
  );
}