// components/SignUpSignInModal.js
import React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Flex,
  Box,
  Heading,
  Text,
  Icon,
  Button,
  VStack,
  useToast
} from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import LandingPage from './LandingPage';

export default function SignUpSignInModal({
  onClose,
  setEmail,
  signInWithGoogle,
  handleSignUpWithEmail,
  isAuthenticated
}) {
  const toast = useToast();

  const closeHandler = () => {
    if (!isAuthenticated) {
      toast({
        title: 'Acción Requerida',
        description: 'Por favor, regístrate o inicia sesión.',
        status: 'warning',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
    } else {
      onClose();
    }
  };

  return (
    <>
   <Flex
  direction={{ base: 'column', md: 'row' }} // Diseño responsivo
  align="flex-start" // Cambiado para iniciar alineación en la parte superior
  justify="center" // Distribución centrada
  minH="100vh"
  mt={15}
  padding="5%"
  gap={{ md: 10 }} // Añade un espacio entre las dos cajas en vistas más amplias
>
  <Box
    textAlign="center"
    width={{ base: 'full', md: '50%' }}
    px={{ base: 2, md: 5 }}
    pt={10} // Iguala el padding superior para que comience en la misma línea
    pb={5} // Padding inferior para asegurar espacio
  >
    <Heading as="h2" size="xl" mb={4}>
      Participa de licitaciones y proyectos de Business Intelligence
    </Heading>
    <Text fontSize="lg" color="gray.600" mb={0}>
      Amplía tu alcance empresarial y explora una riqueza de oportunidades en Business Intelligence de proyectos de nuestros socios.
    </Text>
    <Box
      as="video"
      loading="lazy"
      muted
      autoPlay
      loop
      src="/data-partners.mp4"
      type="video/mp4"
      w="70%"
      mx="auto"
    />
  </Box>

  <Box
    w={{ base: 'full', md: '45%' }}
    bg="whitesmoke"
    boxShadow="2xl"
    rounded="lg"
    p={8}
    pb={20}
    mb={20} // Asegura espacio en vista móvil
    mt={10} // Margen superior para empezar en la misma línea que la caja izquierda
  >
    <VStack spacing={5} align="stretch">
      <Box>
        <Heading size="lg" mb={2} textAlign="start">
          Empieza ahora
        </Heading>
        <Text mb={4} textAlign="start">
          Descubre oportunidades de negocio y recibe notificaciones de proyectos acordes a las habilidades de tu empresa.
        </Text>
      </Box>
      <FormControl id="email-for-signup">
        <FormLabel>Email</FormLabel>
        <Input
          type="email"
          placeholder="Correo electrónico"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          mt={4}
          colorScheme="blue"
          width="full"
          onClick={handleSignUpWithEmail}
        >
          Regístrate
        </Button>
      </FormControl>

      <Button
        leftIcon={<Icon as={FcGoogle} />}
        onClick={signInWithGoogle}
        variant="outline"
        width="full"
      >
        Google
      </Button>

      <Text fontSize="sm">
        Al unirte, aceptas los <Text as="a" color="blue.500" textDecoration="underline">
          Términos de Servicio
        </Text>.
      </Text>
    </VStack>
  </Box>
</Flex>
    <Flex
      direction={{ base: 'column', md: 'row' }}
      align="center"
      justify="space-around"
      w="100%" // Asegurando que ocupe todo el ancho
    >
     <LandingPage />
     </Flex>
     </>
  );
}